<template>
    <div class="kyp-propertree flex-column">
      <section class="kyp-propertree__container flex-row ai-c jc-sb">
        <div class="kyp-propertree__wrapper">
          <SearchInputComponent/>
        </div>
        <div class="kyp-propertree__wrapper flex-row ai-c">
          <ActionItemComponent
          label="Filter"
          iconSize="15px"
          icon="filter-icon.svg"
          fontSize="14px"
          iconBgColor="rgba(12, 15, 74, 0.05)"
          :hasIconColor="false"/>
          <ActionItemComponent
          label="Sort by"
          iconSize="24px"
          icon="filled-sort-icon.svg"
          fontSize="14px"
          :hasIconColor="false"/>
          <ActionItemComponent
          label="New project"
          iconSize="40px"
          icon="add-icon-blue-2.svg"
          fontSize="18px"
          :hasIconColor="false"/>
        </div>
      </section>
      <section class="kyp-propertree__container grid">
        <PropertreeItem v-for="(item, index) in cardCount" :key="index"/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import PropertreeItem from '@/core/components/property/PropertreeItem.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';

export default defineComponent({
  components: {
    PropertreeItem,
    SearchInputComponent,
    ActionItemComponent
  },
  data() {
    return {
      cardCount: 10,
    };
  },
});
</script>
<style lang="scss" scoped>
 .kyp-propertree {
    width: 100%;
    height: 100%;
    gap: 2rem;

    &__container {
      width: 100%;
      gap: 2rem;
    }
 }
 .grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
</style>
