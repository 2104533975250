<template>
    <DefaultCardSlot class="map-widget" cardHeight="623px" :showDropdownMenu="false" padding="0" style="overflow: hidden;">
      <div id="map" style="width: 100%; height: 100%;" v-if="!loadingMap"></div>
    </DefaultCardSlot>
</template>
<script>
import { Loader } from '@googlemaps/js-api-loader';
import { defineComponent } from 'vue';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';

import {
  VUE_APP_GOOGLE_MAP_API,
} from '../../../../config';

export default defineComponent({
  components: {
    DefaultCardSlot
  },
  props: ['selectedTitleData'],
  data() {
    return {
      map: null,
      polygon: null,
      google: null,
    };
  },
  computed: {
    polygons() {
      return this.selectedTitleData?.data?.polygons || []; // Return empty array if no polygons
    }
  },
  mounted() {
    // Load Google Maps API and initialize the map
    this.loadMap();
  },
  watch: {
    selectedTitleData: {
      handler(value) {
        if (value) {
          this.loadMap();
        }
      }
    },
  },
  methods: {
    loadMap() {
      const loader = new Loader({
        apiKey: VUE_APP_GOOGLE_MAP_API,
        version: 'weekly',
        libraries: ['geometry'],
      });

      loader.load().then((google) => {
        this.google = google;
        this.initMap();
      }).catch((error) => {
        console.error('Google Maps API loading error:', error);
      });
    },
    initMap() {
      // Initialize the map
      this.map = new this.google.maps.Map(document.getElementById('map'), {
        center: { lat: 53.719028, lng: -2.072784 },
        zoom: 12,
      });

      const bounds = new this.google.maps.LatLngBounds();

      this.polygons.forEach((polygonData, index) => {
        try {
          const polygon = new this.google.maps.Polygon({
            paths: polygonData.coords,
            strokeColor: 'red',
            strokeWeight: 2,
          });

          // Add click event listener for the polygon
          this.google.maps.event.addListener(polygon, 'click', () => {
            // Create an InfoWindow to display data
            const infoWindow = new this.google.maps.InfoWindow({
              content: `
                <div style="padding-right: 1rem;">
                  <h3>Polygon ${index + 1}</h3>
                </div>
              `,
            });

            // Open the InfoWindow at the polygon's center
            infoWindow.setPosition(polygonData.approx_centre);
            infoWindow.open(this.map);
          });

          // Set the polygon's center
          polygon.setMap(this.map);
          bounds.extend(polygonData.approx_centre);
        } catch (error) {
          console.error('Error creating polygon:', error);
        }
      });

      this.map.fitBounds(bounds);
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

@include media-sm-max-width() {
  .map-widget {
    height: 400px !important;
  }
}
</style>
