<template>
    <div class="kyp-score grid">
      <!-- <section class="kyp-score__container flex-column">
        <DefaultCardSlot cardHeight="330px" :showDropdownMenu="false"> content </DefaultCardSlot>
        <DefaultCardSlot cardHeight="263px" :showDropdownMenu="false"> content </DefaultCardSlot>
      </section>
      <section class="kyp-score__container flex-column">
        <DefaultCardSlot cardHeight="623px" :showDropdownMenu="false"> content </DefaultCardSlot>
      </section> -->
      <EmptyState message="Under construction..." icon="empty-project-icon.svg"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

// import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import EmptyState from '@/core/components/ui/EmptyState.vue';

export default defineComponent({
  components: {
    // DefaultCardSlot,
    EmptyState
  }
});
</script>
<style lang="scss" scoped>
 .kyp-score {
    width: 100%;
    height: 100%;
    gap: 2rem;

    &__container {
      width: 100%;
      gap: 2rem;
    }
 }
 .grid {
    grid-template-columns: 1fr 1fr;
  }
</style>
