<template>
    <DefaultCardSlot class="file-review" cardHeight="623px" :showDropdownMenu="false" style="overflow: hidden;">
      <embed
        v-if="!attachmentLoading"
        :src="embedSrc"
        type="application/pdf"
        width="100%"
        height="100%"
      />
      <div class="h100" v-if="attachmentLoading" v-loading="true"></div>
    </DefaultCardSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import {
  PROPERTY_INSURANCE_CACHE
} from '@/store/modules/attachment-cache/constants';

export default defineComponent({
  components: {
    DefaultCardSlot,
  },

  props: ['pdfUrls'],

  data() {
    return {
      embedSrc: '',
      attachmentLoading: false
    };
  },

  watch: {
    pdfUrls: {
      immediate: true,
      handler(value) {
        if (value && value.length) {
          this.loadAttachment(value[0]);
        }
      }
    },
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadAttachment(value) {
      const cache = PROPERTY_INSURANCE_CACHE;

      this.attachmentLoading = true;

      this.getAttachments({
        name: cache,
        attachments: [value],
        includeUrl: true,
        returnFirstOnly: true
      })
        .then(({ url }) => {
          if (this.isPdfFile(url)) {
            this.embedSrc = url;
          }
        }).catch(() => {})
        .finally(() => {
          this.attachmentLoading = false;
        });
    },

    isPdfFile(type) {
      let bool = type === 'application/pdf';

      if (type && type.indexOf('.pdf') > -1) {
        bool = true;
      }

      return bool;
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

@include media-sm-max-width() {
  .file-review {
    height: 400px !important;
  }
}
</style>
