<template>
    <DefaultCardSlot
      :cardHeight="cardHeight"
      :showDropdownMenu="false">
      <PropertyChecklistContent
        :greenBeltData="greenBeltMockData"
        :nationalParkData="nationalParkMockData"
        :conservationAreaData="conservationAreaMockData"
        :aonbData="aonbMockData"
        :floodRiskData="floodRiskMockData"
        :areaTypeData="areaTypeMockData"/>
    </DefaultCardSlot>
</template>
<script>
import axios from 'axios';
import { defineComponent } from 'vue';

import PropertyChecklistContent from '@/core/components/common/widgets/contents/PropertyChecklistContent.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';

import {
  VUE_APP_PROPERTY_DATA_API,
  VUE_APP_PROPERTY_DATA_URL,
} from '../../../../config';

export default defineComponent({
  props: ['cardHeight', 'property'],
  components: {
    DefaultCardSlot,
    PropertyChecklistContent
  },
  data() {
    return {
      greenBeltData: null,

      nationalParkData: null,

      conservationAreaData: null,

      aonbData: null,

      floodRiskData: null,

      areaTypeData: null,

      greenBeltMockData: {
        status: 'success',
        postcode: 'KT19 9JG',
        postcode_type: 'full',
        green_belt: false,
        green_belt_name: null,
        process_time: '0.19'
      },

      nationalParkMockData: {
        status: 'success',
        postcode: 'KT19 9JG',
        postcode_type: 'full',
        national_park: false,
        national_park_name: null,
        process_time: '0.03'
      },

      conservationAreaMockData: {
        status: 'success',
        postcode: 'KT19 9JG',
        postcode_type: 'full',
        conservation_area: false,
        conservation_area_name: null,
        process_time: '0.42'
      },

      aonbMockData: {
        status: 'success',
        postcode: 'KT19 9JG',
        postcode_type: 'full',
        aonb: false,
        aonb_name: null,
        process_time: '0.23'
      },

      floodRiskMockData: {
        status: 'success',
        postcode: 'KT19 9JG',
        postcode_type: 'full',
        flood_risk: 'None',
        process_time: '0.03'
      },

      areaTypeMockData: {
        status: 'success',
        postcode: 'KT19 9JG',
        postcode_type: 'full',
        area_type: 'Urban major conurbation',
        process_time: '0.02'
      }
    };
  },
  computed: {
  },
  created() {
    // this.initialized();
  },
  methods: {
    async initialized() {
      if (this.property) {
        await this.makeApiCall(this.getGreenBelt, 'greenBeltData');
        await this.makeApiCall(this.getNationalPark, 'nationalParkData');
        await this.makeApiCall(this.getConservationArea, 'conservationAreaData');
        await this.makeApiCall(this.getAonb, 'aonbData');
        await this.makeApiCall(this.getFloodRisk, 'floodRiskData');
        await this.makeApiCall(this.getAreaType, 'areaTypeData');
      }
    },

    async makeApiCall(apiFunction, dataProperty) {
      const requestUrl = apiFunction();
      try {
        const response = await axios.get(requestUrl, {
          headers: {
            Accept: 'application/json',
          }
        });
        this[dataProperty] = response.data;
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: `An error occurred: ${error.message}`
        });
      }
    },

    getGreenBelt() {
      return `${VUE_APP_PROPERTY_DATA_URL}/green-belt?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.removeSpaces(this.property.postcode)}`;
    },
    getNationalPark() {
      return `${VUE_APP_PROPERTY_DATA_URL}/national-park?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.removeSpaces(this.property.postcode)}`;
    },
    getConservationArea() {
      return `${VUE_APP_PROPERTY_DATA_URL}/conservation-area?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.removeSpaces(this.property.postcode)}`;
    },
    getAonb() {
      return `${VUE_APP_PROPERTY_DATA_URL}/aonb?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.removeSpaces(this.property.postcode)}`;
    },
    getFloodRisk() {
      return `${VUE_APP_PROPERTY_DATA_URL}/flood-risk?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.removeSpaces(this.property.postcode)}`;
    },
    getAreaType() {
      return `${VUE_APP_PROPERTY_DATA_URL}/area-type?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.removeSpaces(this.property.postcode)}`;
    },
    removeSpaces(inputString) {
      return inputString.replace(/\s+/g, '');
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
