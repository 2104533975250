<template>
    <div class="custom-radio cursor-pointer" :class="{'active': isActive}">
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['isActive']
});
</script>
<style lang="scss" scoped>
  .custom-radio {
    border: 1px solid rgba(12, 15, 74, 0.5);
    background: #FFF;
    border-radius: 100%;
    width: 20px;
    height: 20px;
  }
  .custom-radio.active {
    background: #FFA500;
  }
</style>
