<template>
    <div class="property-checklist grid">
        <div class="property-checklist__item flex-column ai-c jc-c">
           <StatusState :bgColor="GOLD" :status="greenBeltData?.green_belt"/>
           <p class="property-checklist__label default-text">Green Belt</p>
        </div>
        <div class="property-checklist__item flex-column ai-c jc-c">
           <StatusState :bgColor="GOLD" :status="nationalParkData?.national_park"/>
           <p class="property-checklist__label default-text">National Park</p>
        </div>
        <div class="property-checklist__item flex-column ai-c jc-c">
           <StatusState :bgColor="GOLD" :status="conservationAreaData?.conservation_area"/>
           <p class="property-checklist__label default-text">Conservation Area</p>
        </div>
        <div class="property-checklist__item flex-column ai-c jc-c">
           <StatusState :bgColor="RED" :status="aonbData?.aonb"/>
           <p class="property-checklist__label default-text">Area Of Natural Beauty</p>
        </div>
        <div class="property-checklist__item flex-column ai-c jc-c">
           <StatusState :bgColor="RED" :status="floodRiskData?.flood_risk"/>
           <p class="property-checklist__label default-text">Flood Risk</p>
        </div>
        <div class="property-checklist__item flex-column ai-c jc-c">
           <StatusState :bgColor="RED" :status="areaTypeData?.area_type"/>
           <p class="property-checklist__label default-text">Area Type</p>
        </div>
    </div>
</template>
<script>
import StatusState from '@/core/components/ui/StatusState.vue';

const GOLD = '#D0AD52';
const RED = '#C63A3A';

export default {
  components: {
    StatusState
  },
  props: ['greenBeltData', 'nationalParkData', 'conservationAreaData', 'aonbData', 'floodRiskData', 'areaTypeData'],
  data() {
    return {
      GOLD,
      RED
    };
  },
  created() {
    // this.initialize();
  },
  methods: {
    // initialize() {
    // }
  },
};
</script>
<style lang="scss" scoped>
@use "../../../../../assets/scss/mixins/media-query" as *;
@use "../../../../../assets/scss/mixins/" as *;

.property-checklist {
   grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));

   &__item {
       gap: .3rem;
   }
   &__label {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.10000000149011612px;
   }
}

@media screen and (max-width: 1666px){
  .property-checklist {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    &__label {
      font-size: 10px;
    }
  }
}
</style>
