<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="property-insurance flex-column jc-sb">
        <div class="property-insurance__container flex-column">
          <div class="property-insurance__header flex-row ai-c jc-sb">
              <h2 class="property-insurance__title default-text">Create insurance</h2>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="property-insurance__body flex-column jc-sb">
            <div class="flex-column">
              <el-form :model="propertyInsuranceForm" :rules="rules" ref="form" class="property-insurance__form flex-column" label-position="top">
                    <el-form-item label="Insurance type">
                        <el-select v-model="propertyInsuranceForm.type" class="options" placeholder="E.g. Heating engineer">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Cover name">
                        <el-input v-model="propertyInsuranceForm.coverName" placeholder="Cover name" autocomplete="off" />
                    </el-form-item>
                    <el-form-item label="Coverage £">
                        <el-input
                        class="input-number"
                        v-model="propertyInsuranceForm.coverAmount"
                        placeholder="E.g. £5,000,000"
                        @keypress="isNumber($event, propertyInsuranceForm.coverAmount)"
                        @change="propertyInsuranceForm.coverAmount = convertToDecimal(propertyInsuranceForm.coverAmount)">
                        <!-- <template v-slot:prefix>£</template> -->
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Date issued" v-if="propertyInsuranceForm.type === WARRANTY">
                        <el-date-picker class="date"
                        v-model="propertyInsuranceForm.dateIssued"
                        type="date"
                        :format="DATE_FORMAT"
                        :value-format="'YYYY-MM-DD'"
                        :placeholder="DATE_FORMAT">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Expiry date">
                        <el-date-picker class="date"
                        v-model="propertyInsuranceForm.expiryDate"
                        type="date"
                        :format="DATE_FORMAT"
                        :value-format="'YYYY-MM-DD'"
                        :placeholder="DATE_FORMAT"
                        :disabledDate="disabledDate">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <UploadForm
                v-if="!isFormUpdate"
                :record="propertyInsuranceForm"
                :attachmentSources="attachmentSources"
                :useCustomUploadIcon="true"
                />
            </div>
            <div class="property-insurance__wrapper">
              <Button :isActive="isFormReady" :loading="saving" :buttonText="isFormUpdate ? 'update' : 'save'" @handle-click="onSave"/>
            </div>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>

import { defineComponent } from '@vue/runtime-core';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { DATE_FORMAT } from '@/core/constants';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import NumberHelper from '@/core/helpers/number.helper';
import { PROPERTY_INSURANCE_STORE } from '@/store/modules/property-insurance';

const WARRANTY = 'Warranty';
const INSURANCE = 'Insurance';

export default defineComponent({
  components: {
    BaseModal,
    Button,
    UploadForm
  },

  emits: ['on-cancel', 'reload'],

  props: ['show', 'insuranceId'],

  watch: {
    propertyInsuranceForm: {
      immediate: true,
      deep: true,
      handler() {
        const {
          type,
          expiryDate,
          coverName,
          coverAmount,
          attachments,
        } = this.propertyInsuranceForm;

        if ((type !== '' && expiryDate !== '' && coverName !== '' && coverAmount !== null && attachments.length) && !this.isFormUpdate) {
          this.isFormReady = true;
        } else if ((type !== '' && expiryDate !== '' && coverName !== '' && coverAmount !== null) && this.isFormUpdate) {
          this.isFormReady = true;
        } else {
          this.isFormReady = false;
        }
      }
    },
    insuranceId: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.getInsuranceById();
        }
      }
    },
  },

  data() {
    return {
      WARRANTY,
      INSURANCE,
      DATE_FORMAT,
      isFormReady: false,
      form: {
        type: '',
        expiryDate: '',
        coverName: '',
        coverAmount: null,
        attachments: [],
        dateIssued: ''
      },

      attachmentSources: [],
      options: [
        {
          label: 'Property insurance',
          value: 'Insurance'
        },
        {
          label: 'Warranties',
          value: 'Warranty'
        },
      ],
      disabledDate(date) {
        return date < moment(new Date()).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).toDate();
      },
      saving: false,
      loadingInsurance: false
    };
  },

  computed: {
    ...mapGetters(PROPERTY_INSURANCE_STORE, ['propertyInsuranceForm']),

    isFormUpdate() {
      return this.insuranceId && this.insuranceId !== null;
    }
  },

  methods: {
    ...mapActions(PROPERTY_INSURANCE_STORE, ['createPropertyInsurance', 'getPropertyInsurance', 'setPropertyInsuranceForm', 'updatePropertyInsurance']),

    cancel() {
      this.$emit('on-cancel');
    },

    isNumber(event, value) {
      return NumberHelper.isNumber(event, value);
    },

    convertToDecimal(value) {
      return NumberHelper.convertToDecimal(value);
    },

    async onSave() {
      const propertyId = this.$route.params?.id;
      this.propertyInsuranceForm.coverAmount = Number(this.propertyInsuranceForm.coverAmount);
      let formData = { ...this.propertyInsuranceForm };
      let params = {};

      this.saving = true;

      if (this.isFormUpdate) {
        const id = this.insuranceId;
        delete formData.attachments;

        params = {
          propertyId,
          id,
          formData
        };

        await this.updateInsurance(params);
      } else {
        formData = { propertyId: Number(propertyId), ...this.propertyInsuranceForm };

        params = {
          propertyId,
          formData
        };

        await this.createInsurance(params);
      }
    },

    async createInsurance(params) {
      await this.createPropertyInsurance(params)
        .then(async (response) => {
          if (response) {
            this.$emit('on-close');
            this.$notify.success({
              title: 'Success.',
              message: 'Insurance created successfully.',
            });
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Insurance creation Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.saving = false;
          this.attachmentSources = [];
          this.$emit('reload');
          this.cancel();
        });
    },

    async updateInsurance(params) {
      await this.updatePropertyInsurance(params)
        .then(async (response) => {
          if (response) {
            this.$emit('on-close');
            this.$notify.success({
              title: 'Success.',
              message: 'Insurance updated successfully.',
            });
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Insurance update Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.saving = false;
          this.attachmentSources = [];
          this.$emit('reload');
          this.setPropertyInsuranceForm({ ...this.form });
          this.cancel();
        });
    },

    async getInsuranceById() {
      const propertyId = this.$route.params?.id;
      const id = this.insuranceId;
      const params = {
        propertyId,
        id
      };
      this.loadingInsurance = true;

      await this.getPropertyInsurance(params).then((response) => {
        if (response) {
          delete response?.dateCreated;
          delete response?.dateUpdated;
          delete response?.id;
          delete response?.files;
          this.setPropertyInsuranceForm({ attachments: [], ...response });
        }
      }).catch((e) => {
        this.$notify.error({
          title: 'Error in retrieving property insurance',
          message: e?.data?.message || 'Error occurred when trying to retrieve property insurance.',
        });
      })
        .finally(() => {
          this.loadingInsurance = false;
        });
    }
  },
});
</script>
<style lang="scss" scoped>
  .property-insurance {
    min-width: 500px;
    min-height: 700px;
    position: relative;
    height: 700px;
    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: 100%;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }

    &__actions {
        width: 100%;
        height: auto;
    }

    &__form {
      .el-form-item {
            margin: 0;

        :deep(.el-form-item__label) {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
        }
        :deep(.el-form-item__content) {
            .el-select {
                width: 100%;
            }
            .date {
              .el-input__inner {
                padding-left: 30px;
              }
            }
        }

        :deep(.el-input) {
            width: 100%;
            border-radius: 100px;
            height: 42px;
            background: rgba(12, 15, 74, 0.05);

            .el-input__inner {
                border-radius: 100px;
                outline:none;
                border: none;
                padding: 20px;
                background: transparent;
            }
        }

        :deep(.el-input__suffix) {
            right:20px;
            top: 5px;
            color:#111;
            display: inline-block !important;
        }
      }
    }
 }
</style>
