<template>
    <div class="kyp-insurance">
      <div class="kyp-insurance__container grid" v-if="!loadingInsurances">
        <PdfViewWidget :pdfUrls="selectedInsurance?.files"/>
        <section class="kyp-insurance__wrapper flex-column">
          <InsuranceActionMenuWidget @open-modal="onOpenModal"/>
          <PropertyInsuranceDetailsWidget
            v-if="groupedData.Insurance"
            :insuranceData="groupedData.Insurance"
            :selectedInsurance="selectedInsurance"
            @on-edit="onEdit($event)"
            @set-selected-insurance="setSelectedInsurance($event)"/>
          <WarrantyInsuranceDetailsWidget
            v-if="groupedData.Warranty"
            :warrantyData="groupedData.Warranty"
            :selectedInsurance="selectedInsurance"
            @on-edit="onEdit($event)"
            @set-selected-insurance="setSelectedInsurance($event)"/>
        </section>
      </div>
      <Loader v-show="loadingInsurances" style="top: calc(50% - 0px) !important;"/>

      <!-- MODALS -->
      <KypPropertyInsuranceFormModal :show="showCreateInsuranceModal" @on-cancel="onCloseModal" @reload="initialize" :insuranceId="insuranceId"/>
    </div>
</template>
<script>
import _ from 'lodash';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import KypPropertyInsuranceFormModal from '@/core/components/common/modals/KypPropertyInsuranceFormModal.vue';
import InsuranceActionMenuWidget from '@/core/components/common/widgets/InsuranceActionMenuWidget.vue';
import PdfViewWidget from '@/core/components/common/widgets/PdfViewWidget.vue';
import PropertyInsuranceDetailsWidget from '@/core/components/common/widgets/PropertyInsuranceDetailsWidget.vue';
import WarrantyInsuranceDetailsWidget from '@/core/components/common/widgets/WarrantyInsuranceDetailsWidget.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { PROPERTY_INSURANCE_STORE } from '@/store/modules/property-insurance';

const WARRANTY = 'Warranty';
const INSURANCE = 'Insurance';

export default defineComponent({
  components: {
    PdfViewWidget,
    InsuranceActionMenuWidget,
    PropertyInsuranceDetailsWidget,
    WarrantyInsuranceDetailsWidget,
    KypPropertyInsuranceFormModal,
    Loader
  },

  data() {
    return {
      INSURANCE,
      WARRANTY,
      showCreateInsuranceModal: false,
      // create a mock data temporarily
      data: [
        {
          id: 1,
          type: INSURANCE,
          coverName: 'Building insurance',
          expiryDate: '27/02/2023',
          sumCoverage: '5000000',
          attachments: null,
          issued: null,
          pdfUrl: 'https://www.orimi.com/pdf-test.pdf',
        },
        {
          id: 2,
          type: INSURANCE,
          coverName: 'Contents insurance',
          expiryDate: '27/02/2023',
          sumCoverage: '2000000',
          attachments: null,
          issued: null,
          pdfUrl: 'https://www.africau.edu/images/default/sample.pdf',
        },
        {
          id: 3,
          type: WARRANTY,
          coverName: 'Air source heat pump installation',
          expiryDate: '27/08/2025',
          sumCoverage: '3000000',
          attachments: null,
          issued: '27/08/2023',
          pdfUrl: 'https://file-examples.com/storage/fe235481fb64f1ca49a92b5/2017/10/file-sample_150kB.pdf',
        },
        {
          id: 4,
          type: WARRANTY,
          coverName: 'Driveway installation',
          expiryDate: '27/08/2023',
          sumCoverage: '5400000',
          attachments: null,
          issued: '27/08/2013',
          pdfUrl: 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf',
        },
      ],
      propertyInsurances: [],
      groupedData: {},
      selectedInsurance: null,
      insuranceId: null,
      loadingInsurances: false,
    };
  },

  created() {
    // load the insurance
    this.initialize();
  },

  methods: {
    ...mapActions(PROPERTY_INSURANCE_STORE, ['getPropertyInsurances']),

    onCloseModal() {
      this.insuranceId = null;
      this.showCreateInsuranceModal = false;
    },

    onOpenModal() {
      this.showCreateInsuranceModal = true;
    },

    async initialize() {
      const propertyId = this.$route.params?.id;
      this.loadingInsurances = true;

      await this.getPropertyInsurances(propertyId).then((response) => {
        if (response) {
          this.propertyInsurances = _.cloneDeep(response);
          this.groupDataByType();
          this.setSelectedInsurance(this.propertyInsurances[0]);
        }
      }).catch((e) => {
        this.$notify.error({
          title: 'Error in retrieving property insurances',
          message: e?.data?.message || 'Error occurred when trying to retrieve property insurances.',
        });
      })
        .finally(() => {
          this.loadingInsurances = false;
        });
    },

    groupDataByType() {
      this.groupedData = this.propertyInsurances.reduce((result, item) => {
        // Check if the type property is 'INSURANCE' or 'WARRANTY'
        const itemType = item.type;
        // If the group doesn't exist, create it as an empty array
        if (!result[itemType]) {
          result[itemType] = [];
        }
        // Push the item into the corresponding group
        result[itemType].push(item);

        return result;
      }, {});
    },

    setSelectedInsurance(insurance) {
      this.selectedInsurance = insurance;
    },

    onEdit(id) {
      this.insuranceId = id;
      this.onOpenModal();
    }
  }
});
</script>
<style lang="scss" scoped>
 @use "../../../../assets/scss/mixins/media-query" as *;
 @use "../../../../assets/scss/mixins/" as *;

  .kyp-insurance {
    width: 100%;
    height: 100%;
    position: relative;

    &__container {
      width: 100%;
      height: 100%;
      gap: 2rem;
    }

    &__container.grid {
      grid-template-columns: 1.2fr .8fr;
    }

    &__wrapper {
      gap: 2rem;
    }
  }

  @include media-sm-max-width() {
    .kyp-insurance {
      &__container.grid {
        grid-template-columns: 1fr;
      }
    }
  }
</style>
