<template>
  <CollapsibleSlot class="warranty-insurance" borderRadius="20px" headerPadding="1.3rem .8rem 1.3rem 1.3rem" accordion>
    <template #header-one>
      <div class="warranty-insurance__header flex-row ai-c jc-sb">
        <div class="flex-row ai-c" style="gap: .5rem;">
          <inline-svg :src="require(`@/assets/icons/warranty-insurance-icon.svg`)"></inline-svg>
          <p class="warranty-insurance__text bold default-text">Warranties</p>
        </div>
      </div>
    </template>
    <template #content>
        <div class="warranty-insurance__item flex-column" v-for="(item, index) in warrantyData" :key="index">
          <div class="warranty-insurance__wrapper flex-row ai-c jc-sb">
            <span class="flex-row ai-c" style="gap: .5rem;">
              <CustomRadio @click="setSelectedInsurance(item)" :isActive="isSelected(item)"/>
              <p class="warranty-insurance__text semi-bold default-text">{{ item?.coverName }}</p>
            </span>
            <StatusState :bgColor="dateHelper.isDateExpired(item.expiryDate) ? '#944D00' : '#FFA500'" :status="dateHelper.isDateExpired(item.expiryDate) ? 'Expired' : 'Active'"/>
          </div>
          <div class="flex-column" style="gap: .6rem;">
            <DataWithLabel label="Issued:" :data="$filters.formatToDate(item?.dateIssued)" :noSpaceBetween="true"/>
            <DataWithLabel label="Length of warranty:" :data="calculateWarrantyLength(item.dateIssued, item.expiryDate)" :noSpaceBetween="true"/>
            <DataWithLabel label="Expiry date:" :data="$filters.formatToDate(item?.expiryDate)" :noSpaceBetween="true"/>
          </div>
          <div class="warranty-insurance__wrapper flex-row ai-c jc-fe">
            <ActionItemComponent
              label="Edit"
              fontSize="16px"
              fontColor="#4B4BFF"
              iconColor="#4B4BFF"
              iconSize="19"
              icon="arrow-right.svg"
              @call-to-action="onEdit(item?.id)"
              :isReverse="true"/>
          </div>
        </div>
    </template>
  </CollapsibleSlot>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';

import CollapsibleSlot from '@/core/components/slots/CollapsibleSlot.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import CustomRadio from '@/core/components/ui/CustomRadio.vue';
import DataWithLabel from '@/core/components/ui/DataWithLabel.vue';
import StatusState from '@/core/components/ui/StatusState.vue';
import dateHelper from '@/core/helpers/date-time.helper';

export default defineComponent({
  components: {
    CollapsibleSlot,
    CustomRadio,
    StatusState,
    DataWithLabel,
    ActionItemComponent
  },

  emits: ['set-selected-insurance', 'on-edit'],

  props: ['warrantyData', 'selectedInsurance'],

  data() {
    return {
      dateHelper
    };
  },

  computed: {
  },

  methods: {
    calculateWarrantyLength(issuedDate, expiryDate) {
      const issued = moment(issuedDate);
      const expiry = moment(expiryDate);

      const duration = moment.duration(expiry.diff(issued));

      const years = duration.years();
      const months = duration.months();
      const days = duration.days();

      let result = '';

      if (years > 0) {
        result += `${years} ${years === 1 ? 'year' : 'years'}`;
      }
      if (months > 0) {
        if (result !== '') {
          result += ', ';
        }
        result += `${months} ${months === 1 ? 'month' : 'months'}`;
      }
      if (days > 0) {
        if (result !== '') {
          result += ', ';
        }
        result += `${days} ${days === 1 ? 'day' : 'days'}`;
      }

      return result;
    },

    setSelectedInsurance(insurance) {
      this.$emit('set-selected-insurance', insurance);
    },

    isSelected(insurance) {
      const { selectedInsurance } = this;

      if (selectedInsurance && selectedInsurance !== null) {
        return selectedInsurance.id === insurance?.id;
      }
      return false;
    },

    onEdit(insurance) {
      this.$emit('on-edit', insurance);
    }
  },
});
</script>
<style lang="scss" scoped>
  .warranty-insurance {
    &__item {
        gap: .6rem;
        background: rgba(12, 15, 74, 0.05);
        padding: 1.2rem 1rem;
        box-sizing: border-box;
        border: none;
        border-radius: 16px;
    }
    &__text {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: 0.15px;
    }
    &__text.semi-bold {
      font-weight: 700;
    }
    &__text.bold {
      font-size: 20px;
      font-weight: 700;
    }
  }
</style>
