<template>
  <DefaultCardSlot cardHeight="auto" :showDropdownMenu="false">
    <div class="action-menu flex-column">
      <div class="action-menu__wrapper flex-row ai-c jc-fe">
        <ActionItemComponent
          label="New insurance"
          iconSize="40px"
          icon="add-icon-blue-2.svg"
          fontSize="18px"
          :hasIconColor="false"
          @call-to-action="openModal"/>
      </div>
      <div class="action-menu__wrapper">
        <SearchInputComponent/>
      </div>
    </div>
  </DefaultCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';

export default defineComponent({
  components: {
    DefaultCardSlot,
    ActionItemComponent,
    SearchInputComponent
  },
  emits: ['open-modal'],
  methods: {
    openModal() {
      this.$emit('open-modal');
    }
  }
});
</script>
<style lang="scss" scoped>
.action-menu {
    &__wrapper {
        width: 100%;
    }
}
</style>
