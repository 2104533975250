<template>
  <div class="boundary">
    <div class="boundary__container grid" v-if="!isLoading && titleData.length && titleUseClassData.length">
    <!-- <div class="boundary__container grid" v-if="titleData.length && titleUseClassData.length"> -->
      <BoundaryMapWidget :selectedTitleData="selectedTitle"/>
      <section class="boundary__wrapper flex-column">
        <KypBoundaryWidget
          v-for="(title, index) in titleData" :key="index"
          :title="title" :titleUseClass="titleUseClassData"
          :selectedTitleNumber="selectedTitleNumber"
          @set-title="setTitle($event)"/>
        <PropertyChecklistWidget cardHeight="auto" :property="property"/>
      </section>
    </div>
    <Loader v-show="isLoading && !titleData.length && !titleUseClassData.length"/>
  </div>
</template>
<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import BoundaryMapWidget from '@/core/components/common/widgets/BoundaryMapWidget.vue';
import KypBoundaryWidget from '@/core/components/common/widgets/KypBoundaryWidget.vue';
import PropertyChecklistWidget from '@/core/components/common/widgets/PropertyChecklistWidget.vue';
import Loader from '@/core/components/ui/Loader.vue';
import PropertyService from '@/core/services/properties.service';

import {
  VUE_APP_PROPERTY_DATA_API,
  VUE_APP_PROPERTY_DATA_URL,
} from '../../../../config';

export default defineComponent({
  components: {
    PropertyChecklistWidget,
    KypBoundaryWidget,
    BoundaryMapWidget,
    Loader
  },
  props: ['property'],
  data() {
    return {
      selectedTitle: null,
      selectedTitleNumber: null,

      uprnTitleData: null,
      titleData: [],
      titleUseClassData: [],

      uprnTitleDataCount: 0
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(['setIsLoading']),

    async initialize() {
      const { property } = this;

      this.setIsLoading(true);

      if (property && !property.uprnTitle) {
        await this.getUprnTitleData(property.uprn);
      } else {
        this.uprnTitleData = property.uprnTitle.value;
      }

      if (this.uprnTitleData) {
        await this.getAllTitleData();
        await this.getAllTitleUseClassData();

        this.setIsLoading(false);
      }

      this.setFirstTitle();
    },

    setFirstTitle() {
    // we auto set if there are any found
      if (this.titleData.length) {
        this.setTitle(this.titleData[0]);
      }
    },

    async getUprnTitleData(uprn) {
      const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/uprn-title?key=${VUE_APP_PROPERTY_DATA_API}&uprn=${uprn}`;
      this.setIsLoading(true);
      await axios.get(requestUrl, {
        headers: {
          Accept: 'application/json',
        }
      }).then(async (response) => {
        if (response?.data) {
          this.uprnTitleData = response.data.data;

          await PropertyService.createPropertyDataUprnTitle(this.property.id, {
            value: this.uprnTitleData, uprn: this.uprnTitleData.uprn
          });
        }
      }).catch(() => {
        this.$notify.error({
          title: 'Something went wrong',
          message: 'Please try and refresh the page.'
        });
      });
    },

    async getAllTitleData() {
      const { uprnTitleData, property } = this;
      let titleNumber = '';
      let existingRecord = null;
      const getTitleDataPromises = [];

      if (!this.titleData.length && uprnTitleData) {
        for (let i = 0; i < uprnTitleData.title_data.length; i++) {
          titleNumber = uprnTitleData.title_data[i].title_number;

          if (property.titles) {
            // eslint-disable-next-line no-loop-func
            existingRecord = property.titles.find((title) => title.titleNumber === titleNumber);

            if (existingRecord) {
              this.titleData.push({
                title_number: existingRecord.titleNumber,
                data: existingRecord.value
              });
              // eslint-disable-next-line no-continue
              continue;
            }
          }

          getTitleDataPromises.push(this.getTitleData(titleNumber));
        }
        await Promise.all(getTitleDataPromises)
          .catch(() => {
            // we silent the error in the background
            // this.$notify.error({
            //   title: 'Error in getting title data',
            //   message: 'Error occurred when trying to fetch title data. Please try again.'
            // });
          });
      }
    },

    async getTitleData(titleNumber) {
      const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/title?key=${VUE_APP_PROPERTY_DATA_API}&title=${titleNumber}`;
      setTimeout(async () => {
        this.setIsLoading(true);
        await axios.get(`${requestUrl}`, {
          headers: {
            Accept: 'application/json',
          }
        })
          .then(async (response) => {
            const { data: title } = response;
            this.titleData.push(title);
            this.setFirstTitle();
            await PropertyService.createPropertyDataTitle(this.property.id, {
              value: title.data, titleNumber
            });
          })
          .catch(() => {})
          .finally(() => {
            this.setIsLoading(false);
          });
      }, 1000);
    },

    async getAllTitleUseClassData() {
      const { uprnTitleData, property } = this;
      let titleNumber = '';
      let existingRecord = null;
      const getTitleUseClassDataPromises = [];

      if (!this.titleUseClassData.length && uprnTitleData) {
        for (let i = 0; i < uprnTitleData.title_data.length; i++) {
          titleNumber = uprnTitleData.title_data[i].title_number;

          if (property.titleClasses) {
            // eslint-disable-next-line no-loop-func
            existingRecord = property.titleClasses.find((titleClass) => titleClass.titleNumber === titleNumber);

            if (existingRecord) {
              this.titleUseClassData.push({
                title_number: existingRecord.titleNumber,
                data: existingRecord.value
              });
              // eslint-disable-next-line no-continue
              continue;
            }
          }

          getTitleUseClassDataPromises.push(this.getTitleUseClassData(titleNumber));
        }
        await Promise.all(getTitleUseClassDataPromises)
          .then(() => {
          })
          .catch(() => {
            // this.$notify.error({
            //   title: 'Error in getting title use class data',
            //   message: 'Error occurred when trying to fetch title use class data. Please try again.'
            // });
          });
      }
    },

    async getTitleUseClassData(titleNumber) {
      const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/title-use-class?key=${VUE_APP_PROPERTY_DATA_API}&title=${titleNumber}`;
      setTimeout(async () => {
        this.setIsLoading(true);
        await axios.get(`${requestUrl}`, {
          headers: {
            Accept: 'application/json',
          }
        })
          .then(async (response) => {
            const { data: titleClass } = response;
            this.titleUseClassData.push(titleClass);

            await PropertyService.createPropertyDataTitleClass(this.property.id, {
              value: titleClass.data, titleNumber
            });
          })
          .catch(() => {})
          .finally(() => {
            this.setIsLoading(false);
          });
      }, 1000);
    },
    setTitle(event) {
      this.selectedTitleNumber = event?.title_number;
      this.selectedTitle = event;
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

  .boundary {
    width: 100%;
    height: 100%;
    // position: relative;

    &__container {
      width: 100%;
      height: 100%;
      gap: 2rem;
    }

    &__container.grid {
      grid-template-columns: 1.2fr .8fr;
    }

    &__wrapper {
      gap: 2rem;
    }
  }

  @include media-sm-max-width() {
    .boundary {
      &__container.grid {
        grid-template-columns: 1fr;
      }
    }
  }
</style>
