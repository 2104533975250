<template>
    <DefaultCardSlot cardHeight="300px" padding="0">
      <div class="propertree-item flex-column jc-sb">
        <section class="propertree-item__bg-wrapper">
          <el-image
            class="propertree-item__cover"
            fit="cover"
            :src="require(`@/assets/images/sample-image-three.jpg`)"
          >
          </el-image>
        </section>
        <section class="propertree-item__wrapper flex-row jc-sb">
          <div class="propertree-item__wrapper flex-column ai-fs" style="gap: .3rem;">
            <ProfileVerifiedIcon
              userProfileState="2"
              :smallLayout="false"/>
          </div>
          <PopOverSlot :data="popOverData">
            <div class="propertree-item__icon-wrapper cursor-pointer flex-row ai-c jc-c">
              <el-icon :size="18" color="#0C0F4A" style="transform: rotate(90deg);"><MoreFilled/></el-icon>
            </div>
          </PopOverSlot>
        </section>
        <section class="propertree-item__wrapper flex-row jc-sb">
          <div class="propertree-item__wrapper flex-column ai-fs" style="gap: .3rem;">
            <p class="propertree-item__text bold default-text">Solar PV Installation</p>
            <p class="propertree-item__text default-text">Date of completion: DD/MM/YYYY</p>
          </div>
          <inline-svg :src="require(`@/assets/icons/info-icon-transparent.svg`)"></inline-svg>
        </section>
      </div>
    </DefaultCardSlot>
</template>
<script>
import { MoreFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';

import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';

export default defineComponent({
  components: {
    DefaultCardSlot,
    MoreFilled,
    ProfileVerifiedIcon,
    PopOverSlot
  },
  data() {
    return {
      /* eslint-disable global-require */
      popOverData: [
        {
          icon: `${require('@/assets/icons/view-icon.svg')}`,
          name: 'View',
        },
        {
          icon: `${require('@/assets/icons/share-icon.svg')}`,
          name: 'Share',
        },
        {
          icon: `${require('@/assets/icons/filled-edit-icon.svg')}`,
          name: 'Modify',
        },
        {
          icon: `${require('@/assets/icons/delete-icon.svg')}`,
          name: 'Delete',
        },
      ],
      /* eslint-disable global-require */
    };
  },
});
</script>
<style lang="scss" scoped>
.propertree-item {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  padding: 1rem;
  box-sizing: border-box;

  &__bg-wrapper {
    width: 100%;
    border-radius: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__cover {
    width: 100%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(1deg, rgba(12, 15, 74, 0.90) 13.28%, rgba(12, 15, 74, 0.00) 111.03%);
      filter: drop-shadow(0px 0px 10px rgba(12, 15, 74, 0.20));
    }
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
  }
  &__text {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  &__text.bold {
    font-size: 16px;
    font-weight: 700;
  }

  &__icon-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #FFF;
    min-width: 30px;
  }
}
</style>
