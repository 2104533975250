<template>
    <CollapsibleSlot class="boundary-widget" alignItemsHeader="flex-start" borderRadius="20px" headerGap="1rem" accordion>
      <template #header-one v-if="title">
        <div class="boundary-widget__header flex-row ai-c jc-sb">
            <div class="flex-row ai-c">
              <CustomRadio @click="setTitle(title)" :isActive="isSelected"/>
              <StatusState :bgColor="getBgColor(title?.data?.class)" :status="title?.data?.class"/>
            </div>
        </div>
      </template>
      <template #header-two v-if="title">
        <div class="boundary-widget__header flex-row ai-c jc-sb">
            <DataWithLabel label="Title number" :data="title?.title_number">
            <template #custom-label>
                <el-icon :size="17" color="rgba(12, 15, 74, 0.3)" class="cursor-pointer"><InfoFilled/></el-icon>
            </template>
            </DataWithLabel>
        </div>
      </template>
      <template #content v-if="title">
        <div class="boundary-widget__content flex-column">
          <section class="boundary-widget__wrapper flex-column">
            <DataWithLabel label="Title use class" :data="getTitleUseClass(title?.title_number)">
              <template #custom-label>
                <el-icon :size="17" color="rgba(12, 15, 74, 0.3)" class="cursor-pointer"><InfoFilled/></el-icon>
              </template>
            </DataWithLabel>
            <template v-for="(item, i) in Object.keys(title?.data)" :key="i">
              <DataWithLabel v-if="item === 'estate_interest' || item === 'plot_size'"
                :label="capitalizeFirstLetter(item)" :data="title.data[item]">
                <template #custom-label>
                    <el-icon :size="17" color="rgba(12, 15, 74, 0.3)" class="cursor-pointer"><InfoFilled/></el-icon>
                </template>
              </DataWithLabel>
            </template>
          </section>
          <section class="boundary-widget__wrapper flex-column">
            <el-collapse class="boundary-widget__collapse">
              <el-collapse-item name="Ownership type">
                <template #title>
                  <DataWithLabel label="Ownership type" :data="title.data.ownership.type"/>
                </template>
                <div class="flex-column" v-if="title.data.ownership.details && typeof title.data.ownership.details === 'object'">
                  <DataWithLabel
                    v-for="(item, index) in Object.keys(title.data.ownership.details)" :key="index"
                    :label="capitalizeFirstLetter(item)" :data="title.data.ownership.details[item]">
                  </DataWithLabel>
                </div>
              </el-collapse-item>
              <el-collapse-item name="Polygon count">
                <template #title>
                  <DataWithLabel :label="`Polygon count (${ title?.title_number })`" :data="title.data.polygon_count">
                    <template #custom-label>
                      <el-icon :size="17" color="rgba(12, 15, 74, 0.3)" class="cursor-pointer"><InfoFilled/></el-icon>
                    </template>
                  </DataWithLabel>
                </template>
                <div class="flex-column">
                  <el-collapse class="boundary-widget__polygon-item flex-column">
                    <el-collapse-item style="border-bottom: none;" :name="index" v-for="(polygon, index) in title.data?.polygons" :key="index">
                      <template #title>
                        <DataWithLabel>
                          <template #custom-label>
                            <div class="flex-row ai-c">
                              <el-icon :size="18" :color="BLUE" class="cursor-pointer"><CirclePlusFilled/></el-icon>
                              <p class="default-text">Polygon {{ index + 1 }}</p>
                            </div>
                          </template>
                        </DataWithLabel>
                      </template>
                      <div class="flex-column">
                        <DataWithLabel label="Polygon size" :data="polygon.polygon_size"/>
                        <DataWithLabel label="Approx centre" :data="polygon.approx_centre"/>
                        <DataWithLabel label="Coordinates" :data="polygon.coords"/>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-collapse-item>
              <el-collapse-item name="Associated UPRNs" v-if="getTitle(title?.data?.class) !== LEASEHOLD">
                <template #title>
                  <DataWithLabel label="Associated UPRNs" :data="title?.data?.uprns.length"/>
                </template>
                <div class="boundary-widget__wrapper grid">
                  <StatusState
                    :bgColor="GREY"
                    fontColor="#0C0F4A"
                    boxShadow="0px 0px 0px 0px rgba(12, 15, 74, 0.00)"
                    v-for="(uprn, index) in title?.data?.uprns" :key="index"
                    :status="uprn"/>
                </div>
              </el-collapse-item>
              <el-collapse-item name="Associated Leaseholds" v-if="getTitle(title?.data?.class) !== LEASEHOLD">
                <template #title>
                  <DataWithLabel label="Associated Leaseholds" :data="title?.data?.leaseholds.length"/>
                </template>
                <div class="boundary-widget__wrapper grid">
                  <StatusState
                    :bgColor="GOLD"
                    v-for="(leasehold, index) in title?.data?.leaseholds" :key="index"
                    :status="leasehold"/>
                </div>
              </el-collapse-item>
            </el-collapse>
          </section>
        </div>
      </template>
      <template #empty-state v-else>
        <p class="default-text">No Tasks added</p>
      </template>
    </CollapsibleSlot>
</template>
<script>
import { CirclePlusFilled, InfoFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';

import CollapsibleSlot from '@/core/components/slots/CollapsibleSlot.vue';
import CustomRadio from '@/core/components/ui/CustomRadio.vue';
import DataWithLabel from '@/core/components/ui/DataWithLabel.vue';
import StatusState from '@/core/components/ui/StatusState.vue';

const LEASEHOLD = 'leasehold';
const FREEHOLD = 'freehold';

const GOLD = '#D0AD52';
const BLUE = '#4B4BFF';
const RED = '#C63A3A';
const GREY = 'rgba(12, 15, 74, 0.05)';

export default defineComponent({
  components: {
    CollapsibleSlot,
    StatusState,
    DataWithLabel,
    InfoFilled,
    // RemoveFilled,
    CirclePlusFilled,
    CustomRadio
  },
  emits: ['set-title'],
  props: ['title', 'titleUseClass', 'selectedTitleNumber'],
  data() {
    return {
      LEASEHOLD,
      FREEHOLD,
      GOLD,
      BLUE,
      RED,
      GREY,
    };
  },
  computed: {
    isSelected() {
      const { selectedTitleNumber, title } = this;

      if (selectedTitleNumber && selectedTitleNumber !== null) {
        return selectedTitleNumber === title?.title_number;
      }
      return false;
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      // Remove special characters and underscores and replace them with spaces
      const cleanedString = string.replace(/[^a-zA-Z0-9 ]/g, ' ').trim();

      // Capitalize the first letter of the cleaned string
      return cleanedString.charAt(0).toUpperCase() + cleanedString.slice(1);
    },
    getBgColor(title) {
      if (title.includes(LEASEHOLD)) {
        return GOLD;
      }
      if (title.includes(FREEHOLD)) {
        return BLUE;
      }
      return RED;
    },
    getTitle(title) {
      if (title.includes(LEASEHOLD)) {
        return LEASEHOLD;
      }
      if (title.includes(FREEHOLD)) {
        return FREEHOLD;
      }
      return this.capitalizeFirstLetter(title);
    },
    getTitleUseClass(titleNumber) {
      let titleUseClass = 'N/A';

      if (this.titleUseClass) {
        const foundObject = this.titleUseClass.find((title) => title.title_number === titleNumber);

        if (foundObject !== null && foundObject !== undefined && typeof foundObject !== 'undefined') {
          titleUseClass = foundObject.data['predicted-use-class'];
        }
      }

      return titleUseClass;
    },
    setTitle(event) {
      this.$emit('set-title', event);
    }
  },
});
</script>
<style lang="scss" scoped>
  .boundary-widget {
    &__header {
      height: 22px;
    }

    &__content {
      padding-top: 1rem;
      margin-right: 1rem;
      border-top: 1px solid rgba(12, 15, 74, 0.2);
    }

    &__wrapper.grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 2rem;
    }

    &__collapse {
      border-bottom: none;
      :deep(.el-collapse-item__header) {
        padding-left: 0;
        padding-right: 0;
        align-items: center;
      }
      :deep(.el-collapse-item__wrap) {
        padding: 0;
      }
      .el-collapse-item {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(12, 15, 74, 0.2);
        }
      }
    }

    &__polygon-item {
      border-bottom: none;
      padding-top: 1rem;
      :deep(.el-collapse-item__header) {
        height: 20px;
      }
      :deep(.el-collapse-item__arrow) {
        display: none;
      }
      :deep(.el-collapse-item__content) {
        padding: 0 1rem;
      }
      .el-collapse-item {
        background: rgba(12, 15, 74, 0.05);
        border-radius: 10px;
      }
    }
  }
</style>
