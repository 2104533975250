<template>
  <div class="kyp-page flex-column">
    <div class="kyp-page__container">
      <DefaultCardSlot cardHeight="auto" :showDropdownMenu="false">
        <PropertyDetailsContent :property="property" :disableEdit="true"/>
      </DefaultCardSlot>
    </div>
    <div class="kyp-page__container">
      <SecondaryMenuSlot>
        <SecondaryMenuItem
        v-for="(menu, index) in menus" :key="index"
        :menu="menu"
        :currentMenuId="currentMenuId"
        @handle-click="setScreen($event)"/>
      </SecondaryMenuSlot>
    </div>
    <div class="kyp-page__container">
      <KypScoreScreen v-if="currentMenuId === SCORE_SCREEN"/>
      <KypPropertreeScreen v-if="currentMenuId === PROPERTREE_SCREEN"/>
      <KypBoundaryScreen v-if="currentMenuId === BOUNDARY_SCREEN && property" :property="property"/>
      <KypEpcScreen v-if="currentMenuId === EPC_SCREEN"/>
      <KypFloorPlanScreen v-if="currentMenuId === FLOOR_PLAN_SCREEN"/>
      <KypInsuranceScreen v-if="currentMenuId === INSURANCE_SCREEN"/>
      <KypTitleDeedsScreen v-if="currentMenuId === TITLE_DEEDS_SCREEN"/>
      <KypPlanningScreen v-if="currentMenuId === PLANNING_SCREEN"/>
      <KypValuationScreen v-if="currentMenuId === VALUATION_SCREEN"/>
      <KypDevicesScreen v-if="currentMenuId === DEVICES_SCREEN"/>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import PropertyDetailsContent from '@/core/components/common/widgets/contents/PropertyDetailsContent.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import SecondaryMenuSlot from '@/core/components/slots/SecondaryMenuSlot.vue';
import SecondaryMenuItem from '@/core/components/ui/SecondaryMenuItem.vue';
import KypBoundaryScreen from '@/modules/property-details-children/components/kyp-screens/KypBoundaryScreen.vue';
import KypDevicesScreen from '@/modules/property-details-children/components/kyp-screens/KypDevicesScreen.vue';
import KypEpcScreen from '@/modules/property-details-children/components/kyp-screens/KypEpcScreen.vue';
import KypFloorPlanScreen from '@/modules/property-details-children/components/kyp-screens/KypFloorPlanScreen.vue';
import KypInsuranceScreen from '@/modules/property-details-children/components/kyp-screens/KypInsuranceScreen.vue';
import KypPlanningScreen from '@/modules/property-details-children/components/kyp-screens/KypPlanningScreen.vue';
import KypPropertreeScreen from '@/modules/property-details-children/components/kyp-screens/KypPropertreeScreen.vue';
import KypScoreScreen from '@/modules/property-details-children/components/kyp-screens/KypScoreScreen.vue';
import KypTitleDeedsScreen from '@/modules/property-details-children/components/kyp-screens/KypTitleDeedsScreen.vue';
import KypValuationScreen from '@/modules/property-details-children/components/kyp-screens/KypValuationScreen.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';

const SCORE_SCREEN = 1;
const PROPERTREE_SCREEN = 2;
const BOUNDARY_SCREEN = 3;
const EPC_SCREEN = 4;
const FLOOR_PLAN_SCREEN = 5;
const INSURANCE_SCREEN = 6;
const TITLE_DEEDS_SCREEN = 7;
const PLANNING_SCREEN = 8;
const VALUATION_SCREEN = 9;
const DEVICES_SCREEN = 10;

export default defineComponent({
  components: {
    PropertyDetailsContent,
    DefaultCardSlot,
    SecondaryMenuSlot,
    SecondaryMenuItem,
    KypScoreScreen,
    KypPropertreeScreen,
    KypBoundaryScreen,
    KypEpcScreen,
    KypFloorPlanScreen,
    KypInsuranceScreen,
    KypTitleDeedsScreen,
    KypPlanningScreen,
    KypValuationScreen,
    KypDevicesScreen
  },
  data() {
    return {
      property: null,
      currentMenuId: 3,
      menus: [
        // {
        //   id: 1,
        //   name: 'KYP score',
        //   icon: 'score-icon.svg',
        // },
        // {
        //   id: 2,
        //   name: 'Propertree',
        //   icon: 'propertree-icon.svg',
        // },
        {
          id: 3,
          name: 'Boundary',
          icon: 'boundery-icon.svg',
        },
        // {
        //   id: 4,
        //   name: 'EPC+',
        //   icon: 'epc-icon.svg',
        // },
        // {
        //   id: 5,
        //   name: 'Floor plan',
        //   icon: 'floor-plan-icon.svg',
        // },
        {
          id: 6,
          name: 'Insurance',
          icon: 'insurance-icon-2.svg',
        },
        // {
        //   id: 7,
        //   name: 'Title deeds',
        //   icon: 'title-deeds-icon.svg',
        // },
        // {
        //   id: 8,
        //   name: 'Planning',
        //   icon: 'planning-icon.svg',
        // },
        // {
        //   id: 9,
        //   name: 'Valuation',
        //   icon: 'valuation-icon.svg',
        // },
        // {
        //   id: 10,
        //   name: 'Devices',
        //   icon: 'devices-icon.svg',
        // },
      ],

      SCORE_SCREEN,
      PROPERTREE_SCREEN,
      BOUNDARY_SCREEN,
      EPC_SCREEN,
      FLOOR_PLAN_SCREEN,
      INSURANCE_SCREEN,
      TITLE_DEEDS_SCREEN,
      PLANNING_SCREEN,
      VALUATION_SCREEN,
      DEVICES_SCREEN,
    };
  },
  async created() {
    await this.initialize();
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions(['setIsLoading']),
    ...mapActions(PROPERTY_STORE, ['getProperty']),

    async initialize() {
      const { id } = this.$route.params;
      await this.getProperty(id)
        .then((raw) => {
          const result = {
            ...raw,
            addressData: JSON.parse(raw.addressData),
            epcData: JSON.parse(raw.epcData),
          };

          if (!raw.subscription) {
            this.routeToPropertyListing();
          }

          this.property = result;
        })
        .finally(() => {
          this.setIsLoading(false);
        });
    },
    setScreen(id) {
      this.currentMenuId = id;
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.kyp-page {
  max-width: 1230px;
  padding: 0.9rem 0.5rem 1rem 0.5rem;

  &__container {
    width: 100%;
    height: 100%;
    gap: 2rem;
  }
}
</style>
